.spinner {
  text-align: center;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  height: 100%;
}

.layout-header {
  height: 80px;
  color: #fff;
  background-color: #760e19;
}

.breadcrumb-class {
  font-size: 18px;
  font-weight: bold;
  margin: 16px 0;
}

.content-class {
  margin: 12px 12px 0;
  overflow: initial;
  min-height: 650px;
}

.input-number-class {
  width: 100%;
  padding-right: 20px;
}

.input-number-class input {
  text-align: right !important;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dynamic-delete-button:hover {
  color: #777;
}

.login {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-card {
  width: 50vh;
  padding-right: 20px;
  padding-left: 20px;
}

.form-input {
  color: black !important;
}

.form-label {
  font-weight: bold;
}

.ant-picker-cell-inner {
  text-transform: capitalize;
}

.ant-picker-input > input {
  text-transform: capitalize;
}

.ant-input {
  text-transform: capitalize;
}

.input-class {
  text-transform: none !important;
}

textarea {
  text-transform: none !important;
}

.menu-item {
  width: 24px;
  justify-content: space-between;
}

.ant-layout-sider-trigger {
  color: black !important;
  background: white !important;
}

.ant-layout-sider {
  background: white !important;
}

.avatar-class {
  background-color: #ffbf00;
  color: black;
  font-weight: bold;
}

.schedule-radio-button {
  text-transform: capitalize;
  margin-right: 10px;
}

.schedule-checkbox {
  text-transform: capitalize;
  margin-right: 10px;
  width: 100px;
  height: 40px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.schedule-checkbox .ant-checkbox {
  display: none;
}

.schedule-checkbox-pressed {
  color: white;
  background-color: #0070C0;
}
